<template lang="html">
    <footer class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-12 py-20 max-w-[1200px] 
                   mx-auto gap-10 border-t px-5" >
        <div class="flex flex-col gap-4 md:col-span-4">
            <h4 class="text-3x1 font-bold text-gray-500">
                Pytheas <span class="text-blue-500">DB</span>
            </h4>
            <p>PytheasDB is a rare disease database.</p>
        </div>
        <div class="flex flex-col gap-4 md:col-span-2">
            <h4 class="text-3x1 font-bold text-gray-700"> Contact </h4>
            <div class="flex flex-col gap-3">
                <!-- <a href="/" class="font-[400] text-lg 
                                   hover:text-blue-700 transition duration-300 ease-in-out">
                                Home </a>
                <a href="/about" class="font-[400] text-lg 
                                   hover:text-blue-700 transition duration-300 ease-in-out">
                                About </a>
                <a href="/recipes" class="font-[400] text-lg 
                                   hover:text-blue-700 transition duration-300 ease-in-out">
                                Recipes </a>
                <a href="/download" class="font-[400] text-lg 
                                   hover:text-blue-700 transition duration-300 ease-in-out">
                                Download </a> -->
                <a href="/contact" class="font-[400] text-base 
                                   hover:text-blue-700 transition duration-300 ease-in-out">
                                Contact </a>
            </div>
        </div>
        <div class="flex flex-col gap-4 md:col-span-2">
           <h4 class="text-3x1 font-bold text-gray-700"> Contributors </h4>
            <div class="flex flex-col gap-3">
                <a href="#" class="font-[400] text-base 
                                   hover:text-blue-700 transition duration-300 ease-in-out">
                                List </a>
                <!-- <a href="#" class="font-[400] text-lg  -->
                                   <!-- hover:text-blue-700 transition duration-300 ease-in-out"> -->
                                <!-- Support </a> -->
                <!-- <a href="#" class="font-[400] text-lg  -->
                                   <!-- hover:text-blue-700 transition duration-300 ease-in-out"> -->
                                <!-- Feedback </a> -->
                <!-- <a href="#" class="font-[400] text-lg  -->
                                   <!-- hover:text-blue-700 transition duration-300 ease-in-out"> -->
                                <!-- Accessibility </a> -->
                <!-- <a href="#" class="font-[400] text-lg  -->
                                   <!-- hover:text-blue-700 transition duration-300 ease-in-out"> -->
                                <!-- FAQs </a> -->
            </div> 
        </div>
        <div class="flex flex-col gap-4 md:col-span-2">
            <h4 class="text-3x1 font-bold text-gray-700">
                FAQ
            </h4>
            <a href="#" class="font-[400] text-base 
                                   hover:text-blue-700 transition duration-300 ease-in-out">
                                List </a>
        </div>
        <div class="flex flex-col gap-4 md:col-span-2">
            <h4 class="text-3x1 font-bold text-gray-700">
                Sponsors
            </h4>
            <a href="https://mirumpharma.com/">
                <img style="height:60px; width:auto;" src="https://companieslogo.com/img/orig/MIRM_BIG-64e8aaee.png?t=1644879644">
            </a>
        </div> 
    </footer>
    <div class="bg-blue-600 text-white text-center py-3 px-5">
        <p style="display:inline-block;"> &copy; PytheasDB by GGS 2024. Supported by Mirum. </p>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>